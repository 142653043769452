import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { Customer } from "../../../types/customer";
import { useDashboardContext } from "../context/dashboard-context";
import { Icon } from "@iconify/react";
import Iconify from "../../common/Iconify";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../helpers/routes";
import { DeleteCustomerDialog } from "../../Dialogs/DeleteCustomerDialog";
import { DeleteCustomerDialogDashboard } from "../../Dialogs/DeleteCustomerDialogDashboard";

interface Props {
  customer: Customer;
}

export const CustomerTableRow = ({ customer }: Props) => {
  const {
    deleteCustomer: { handleDeleteCustomerById },
    deleteCustomerDialog: {
      deleteCustomerDialogOpen,
      handleDeleteCustomerDialogOpen,
      handleDeleteCustomerDialogClose,
    },
  } = useDashboardContext();
  return (
    <TableRow sx={{ height: 70 }}>
      <TableCell>
        <Link
          to={
            "customers/" +
            ROUTES.dashboard.customers.profile.link +
            customer?.id
          }
          style={{ textDecoration: "none", color: "black" }}
        >
          {customer?.lastName} {customer?.firstName}
        </Link>
      </TableCell>
      <TableCell>{customer?.email}</TableCell>
      <TableCell>{customer?.telephone}</TableCell>
      <TableCell>
        {customer?.address} {customer?.addressNumber}, {customer?.zipCode},
        {customer?.region}
      </TableCell>
      <TableCell>
        <IconButton
          aria-label="delete"
          onClick={() => handleDeleteCustomerDialogOpen()}
        >
          <Iconify icon={"solar:trash-bin-minimalistic-bold"} height={25} />
        </IconButton>
      </TableCell>
      <DeleteCustomerDialogDashboard customerId={customer?.id ?? 0} />
    </TableRow>
  );
};
